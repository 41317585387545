body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primarycolor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --primarycolor: #c52f33

}

#box-create-venda {
  position: absolute;
  padding: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  max-width: 450px;
  max-height: 90%;
  background: whitesmoke;
  border: 2px solid #000;
  size: 4;
  color: rgb(0, 0, 0);
  border-radius: 1.5rem;
  overflow: overlay;
}

#box-view-itens::-webkit-scrollbar,
#box-create-venda::-webkit-scrollbar
{
  width: 0px;
}

#box-video-itens {
  position: absolute;
  padding: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  max-width: 100%;
  height: 400px;
  background: whitesmoke;
  border: 2px solid #000;
  size: 4;
  color: white;
  border-radius: 1.5rem;
  overflow: overlay;
}

#close {
  position: absolute;
  right: 5px;
  top: 5px
}

.divprint {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}